<template>
  <el-container style="border: 1px solid #eee">
    <el-header style="text-align: right; font-size: 12px">
      <el-col :md="2">
        <ul class="el-menu--horizontal el-menu">
          <li class="el-menu-item">
            <a href="/map" style="text-decoration-line: none">
              <img src="@/assets/img/icon/logo.png" class="el-avatar--circle el-avatar--medium" alt="img">
              map
            </a>
          </li>
        </ul>
      </el-col>
      <el-col :md="20">
        <el-menu
          :default-active="this.$route.path"
          router
          mode="horizontal"
        >
          <el-menu-item index="/map/photo">
            <i class="el-icon-map-location" />
            <span slot="title">照片地图</span>
          </el-menu-item>
          <el-menu-item index="/map/trail">
            <i class="el-icon-map-location" />
            <span slot="title">轨迹地图</span>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col :md="2">
        <el-dropdown>
          <img
            :src="user.avatarUrl"
            class="el-avatar--circle el-avatar--medium"
            style="margin-right: 10px; margin-top: 15px"
            alt=""
          >
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              icon="el-icon-s-platform"
              class="size"
              @click.native="goToHome"
            >主站</el-dropdown-item>
            <el-dropdown-item
              icon="el-icon-error"
              class="size"
              @click.native="goToLogout"
            >退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
    </el-header>
    <el-container>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { userMixin } from 'assets/js/mixin'
import { getAuthedUser } from '@/utils/auth'

export default {
  name: 'MapIndex',
  mixins: [userMixin],
  data() {
    return {
      user: null
    }
  },
  watch: {
    // 地址栏 url 发生变化时重新加载本页面
    $route() {
      this.$router.go()
    }
  },
  created() {
    const userInfo = getAuthedUser()
    if (userInfo !== null) {
      this.user = userInfo
    }
  }
}
</script>

<style>
</style>
